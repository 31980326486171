// General
import './css/App.css';
import { GetVersionNum } from './util/VersionNumber';
import NoSleep from 'nosleep.js';
import { checkGuestExpiration } from './util/guestDataHandler';
import { CheckLoggedIn } from './firebase/firebase-handler';

// React
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

console.log(`[main] started party-trivia-web-app ${GetVersionNum()}, env[${process.env.REACT_APP_PRODUCT_ENV}]`);
const loggedIn = await CheckLoggedIn();
console.log(`[main] user logged in: ${loggedIn}`);

checkGuestExpiration();

var noSleep = new NoSleep();
document.addEventListener('click', function enableNoSleep() {
    document.removeEventListener('click', enableNoSleep, false);
    noSleep.enable();
    console.log('[OnClick] Enabled no sleep on website');
  }, false);

// Function to hide the address bar
function hideAddressBar() {
    if (window.innerHeight > window.innerWidth) {
        // Portrait mode
        window.scrollTo(0, 1);
    } else {
        // Landscape mode
        window.scrollTo(1, 0);
    }
    console.log("hideAddressBar")
}

// Hide address bar when the page is loaded
window.addEventListener('load', function() {
    setTimeout(hideAddressBar, 0);
});

// Hide address bar on orientation change
window.addEventListener('orientationchange', hideAddressBar);
hideAddressBar();


const urlParams = new URLSearchParams(window.location.search); // Get the current URL
const code = urlParams.get('jc'); // Get the value of the 'joincode' parameter
if (code !== null && code !== '') {
    localStorage.setItem("SavedJoinCode", code);
    console.log(`[index] saved the joincode: ${code}`);
}

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <div className="App">
            <div className="unity-container">
                <App/>
            </div>
        </div>
    </React.StrictMode>
);

// Disabling for now
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register(); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
