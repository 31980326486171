import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase-handler';
import { NavLink, useNavigate } from 'react-router-dom';

export const EmailLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const onLogin = e => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then(userCredential => {
                // Signed in
                const user = userCredential.user;
                navigate('/');
                console.log('[onLogin] succesfully loged in with data:', user);
            })
            .catch(error => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setErrorMsg(`${errorCode}: ${errorMessage}`);
            });
    };

    const goBack = () => {
        navigate('/');
    };

    const goToResetPassword = () => {
        navigate('/resetpassword');
    };

    const SignUp = () => {
        console.log('User selected Sign Up');
        navigate('/signup');
    };

    const goGuestLogin = () => {
        navigate('/guestlogin');
    };


    return (
        <div>
            <div className="center fadeIn">
                <div className='uk-text-center'>
                    <span className='subheader'>Login</span>
                    <br></br>
                    <br></br>
                    <div className="input-container">
                        <input
                            className="input-field"
                            type="email"
                            placeholder="enter email"
                            onInput={e => setEmail(e.target.value)}
                        />
                        <img src="/imgs/userIcon.png" alt="" className="input-icon" />
                    </div>

                    <div className="input-container iconRight">
                        <input
                            className="input-field"
                            type="password"
                            placeholder="enter password"
                            onInput={e => setPassword(e.target.value)}
                        />
                        <img src="/imgs/pwIcon.png" alt="" className="input-icon" />
                    </div>
                    
                    <button className='text-btn fixedSize' onClick={goToResetPassword}>
                        Forgot password
                    </button>
                    
                    <br></br>

                    <button className='button fixedSize' onClick={onLogin}>
                        Login
                    </button>
                    
                    <font color='red'>{errorMsg}</font>
                    <br></br>
                    <br></br>

                    <img src='/imgs/divider.png' width='300' height='75' alt='-------------'  />
                    
                    <br></br>
                    <br></br>

                    <button className='button fixedSize' onClick={SignUp}>
                        Sign Up
                    </button>

                    <button className='button fixedSize' onClick={goGuestLogin}>
                        Play as Guest
                    </button>
                </div>
            </div>

            <div className='overlay_button_container' onClick={goBack}>
                <img src='/imgs/arrow.png' width='36' height='36' alt='back'></img>
            </div>
        </div>
    );
};
