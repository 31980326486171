import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { auth, signInWithGoogle, signInWithFacebook } from "../firebase/firebase-handler";
import '../css/App.css';
import { VersionNumber } from "../util/VersionNumber";

export const WelcomePage = () => {
    console.log(`Load WelcomePage`);
    const navigate = useNavigate();

    const continueWithEmail = async () => {
        navigate("/login");
    };

    const onError = (error) => {
        console.log(`[WelcomePage] got the error: ${error}`);
    };

    const signInFacebook = () =>{
        console.log(`[WelcomePage] attempt sign in with facebook`);
        signInWithFacebook(onError);
    }

    const signInGoogle = () =>{
        console.log(`[WelcomePage] attempt sign in with google`);
        signInWithGoogle(onError);
    }

    return (
        <div>
            <div className="center">
                <span className='welcome-header'>Welcome to...</span>
                <br></br>
                <span className='header'>Party Trivia</span>
                <br></br>
                <br></br>
                <img src='/imgs/game/logo-big.png' width='184.1' height='257.6' alt='' />
                <br></br>
                <br></br>
                <button className='button fixedSize facebook-btn' onClick={signInFacebook}>
                    <img src='/imgs/facebookIcon.png' width='25' height='25' alt='' />
                    Continue with Facebook
                </button>

                <button className='button fixedSize google-btn' onClick={signInGoogle}>
                    <img src='/imgs/googleIcon.png' width='25' height='25' alt='' />
                    Continue with Google
                </button>

                <button className='button fixedSize' onClick={continueWithEmail}>
                    <img src='/imgs/mailIcon.png' width='25' height='25' alt='' />
                    Continue with Email
                </button>
            </div>
            <VersionNumber></VersionNumber>
        </div>
    );
};
