import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase-handler';

export const EmailSignUp = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [acceptedTOS, setAcceptedTOS] = useState(false);
    const [acceptedPP, setAcceptedPP] = useState(false);

    const onSubmit = async e => {
        e.preventDefault();

        if(!acceptedTOS)
        {
            console.log('[EmailSignUp] Player has not accepted TOS');
            setErrorMsg(`Error: Please accept the Terms of Service to continue`) 
            return;
        }

        if(!acceptedPP)
        {
            console.log('[EmailSignUp] Player has not accepted Privacy Policy');
            setErrorMsg(`Error: Please accept the Privacy Policy to continue`) 
            return;
        }

        if(email == confirmEmail && password == confirmPassword)
        {
            await createUserWithEmailAndPassword(auth, email, password)
                .then(userCredential => {
                    // Signed in
                    const user = userCredential.user;
                    console.log(user);
                    navigate('/');
                })
                .catch(error => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage);
                    setErrorMsg(`${errorCode}: ${errorMessage}`) ;
                });

        }else{
            setErrorMsg('Password or Email does not match') ;
        }

    };

    const goBack = () => {
        navigate('/login');
    };

    const toggleToSAgreed = () => {
        setAcceptedTOS(!acceptedTOS);
    };

    const togglePPAgreed = () => {
        setAcceptedPP(!acceptedPP);
    };

    const getFullUrl = (path) => {
        return `${window.location.origin}${path}`;
    };

    return (
        <div>
            <div className='center fadeIn'>
                <div className='uk-text-center'>
                    <span className='subheader'>Sign Up</span>
                    <br></br>
                    <br></br>
                    <div className='input-container' id='email'>
                        <input
                            className='input-field'
                            type='email'
                            placeholder='enter email'
                            onInput={e => setEmail(e.target.value)}
                        />
                        <img src='/imgs/mailIcon_dark.png' alt='' className='input-icon' />
                    </div>

                    <div className='input-container' id='confirmEmail'>
                        <input
                            className='input-field'
                            type='email'
                            placeholder='confirm email'
                            onInput={e => setConfirmEmail(e.target.value)}
                        />
                        <img src='/imgs/mailIcon_dark.png' alt='' className='input-icon' />
                    </div>

                    <div className='input-container iconRight' id='password'>
                        <input
                            className='input-field'
                            type='password'
                            placeholder='enter password'
                            onInput={e => setPassword(e.target.value)}
                        />
                        <img src='/imgs/pwIcon.png' alt='' className='input-icon' />
                    </div>

                    <div className='input-container iconRight' id='confirmPassword'>
                        <input
                            className='input-field'
                            type='password'
                            placeholder='confirm password'
                            onInput={e => setConfirmPassword(e.target.value)}
                        />
                        <img src='/imgs/pwIcon.png' alt='' className='input-icon' />
                    </div>
                    
                    <br></br>

                    <button className={`toggle-button fixedSize ${acceptedTOS ? 'on' : 'off'}`} onClick={toggleToSAgreed}>
                        <input 
                            type="checkbox" 
                            className="checkbox" 
                            checked={acceptedTOS} 
                            onChange={toggleToSAgreed} 
                        />
                         I accept Party Trivia's 
                        <a 
                            href={getFullUrl(`/tos`)} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="button-link"
                        >
                            Terms of Service
                        </a>
                    </button>

                    <button className={`toggle-button fixedSize ${acceptedPP ? 'on' : 'off'}`} onClick={togglePPAgreed}>
                        <input 
                            type="checkbox" 
                            className="checkbox" 
                            checked={acceptedPP} 
                            onChange={togglePPAgreed} 
                        />
                        I accept Party Trivia's 
                        <a 
                            href={getFullUrl(`/privacypolicy`)} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="button-link"
                        >
                            Privacy Policy
                        </a>
                    </button>

                    
                    <br></br>

                    <button className='button fixedSize' onClick={onSubmit}>
                        Create Account
                    </button>

                    <font color='red'>{errorMsg}</font>
                </div>
            </div>

            <div className='overlay_button_container' onClick={goBack}>
                <img src='/imgs/arrow.png' width='36' height='36' alt='back'></img>
            </div>
        </div>
    );
};
