import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase/firebase-handler';
import { NavLink, useNavigate } from 'react-router-dom';

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const [success, setSentSuccesfully] = useState(false);
    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const onResetPassword = e => {
        e.preventDefault();

        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                setSentSuccesfully(true);
                setErrorMsg(`Successfully sent reset email. Please check your inbox.`);
                console.log('[onResetPassword] Successfully sent reset email to:', email);
            })
            .catch(error => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setSentSuccesfully(false);
                setErrorMsg(`Error: ${errorCode}`);
            });
    };

    const goBack = () => {
        navigate('/login');
    };

    return (
        <div>
            <div className="center fadeIn">
                <div className='uk-text-center'>
                    <span className='subheader'>Reset Password</span>
                    <br></br>
                    <br></br>
                    <div className="input-container">
                        <input
                            className="input-field"
                            type="email"
                            placeholder="enter email"
                            onInput={e => setEmail(e.target.value)}
                        />
                        <img src="/imgs/userIcon.png" alt="" className="input-icon" />
                    </div>

                    <br></br>
                    <button className='button fixedSize' onClick={onResetPassword}>
                        Reset Password
                    </button>
                    
                    <font color= { success ? 'white' : 'red'}>{errorMsg}</font>
                </div>
            </div>

            <div className='overlay_button_container' onClick={goBack}>
                <img src='/imgs/arrow.png' width='36' height='36' alt='back'></img>
            </div>
        </div>
    );
};
