import React from 'react';

export const CancelButton = ({ onClick }) => {
  
  return (
    <button
      onClick={onClick}
      style={{
        position: 'fixed',
        top: '10px',    // Distance from the top of the window
        right: '10px',  // Distance from the right side of the window
        width: '30px',  // Width of the button
        height: '30px', // Height of the button
        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black background
        color: '#ffffff',  // White color for the X
        fontSize: '20px',   // Font size for the X
        border: 'none',     // No border
        borderRadius: '5px', // Rounded corners
        cursor: 'pointer',  // Pointer cursor on hover
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',  // Prevent text selection
      }}
    >
      X
    </button>
  );
};
