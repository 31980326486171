// src/firebase-handler.js
import { initializeApp } from 'firebase/app';
import {
    // user
    getAuth,
    signOut,
    deleteUser,

    // sign in
    signInWithPopup,
    signInWithCredential,
    fetchSignInMethodsForEmail,
    signInWithRedirect,
    signInWithEmailAndPassword,

    // linking accounts
    getRedirectResult,
    isSignInWithEmailLink,
    linkWithCredential,

    // providers
    EmailAuthProvider,
    GoogleAuthProvider,
    FacebookAuthProvider,
} from 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyCeAq0i1WGLviKAQlD6P0lhx3nqHGvz7OA',
    authDomain: 'partytrivia-e27c0.firebaseapp.com',
    databaseURL: 'https://partytrivia-e27c0.firebaseio.com',
    projectId: 'partytrivia-e27c0',
    storageBucket: 'partytrivia-e27c0.appspot.com',
    messagingSenderId: '242906059254',
    appId: '1:242906059254:web:b1ba331e6bf005946777d7',
    measurementId: 'G-0ZEQP1JJ68',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

export const signInWithGoogle = async onError => {
    try {
        console.log(`[signInWithGoogle] attempting to login with google`);
        const result = await signInWithPopup(auth, googleProvider);
        console.log(`[signInWithGoogle] Success got the result:`, result);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log(`[signInWithGoogle] got the token: ${token}`);

        const user = result.user;
        console.log(`[signInWithGoogle] User signed in with UID: ${user.uid}`);
    } catch (error) {
        console.error(`[signInWithGoogle] Error: ${error}`);
        if (onError) {
            onError(error.message);
        }
    }
};

export const signInWithFacebook = async onError => {
    window.FB.login(
        response => {
            if (response.authResponse) {
                const { accessToken } = response.authResponse;

                // Use the access token to create a Firebase credential
                const credential = FacebookAuthProvider.credential(accessToken);
                const auth = getAuth();

                signInWithCredential(auth, credential)
                    .then(userCredential => {
                        const user = userCredential.user;
                        console.log('User signed in:', user);

                        // Get the user's profile picture URL
                        const profilePictureUrl = user.photoURL;
                        console.log('Profile Picture URL:', profilePictureUrl);
                    })
                    .catch(async error => {
                        console.error('Error signing in:', error);
                        if (onError) onError(error);
                    });
            } else {
                console.error('User cancelled login or did not fully authorize.');
            }
        },
        { scope: 'email,public_profile' },
    );
};

export const LinkAccount = async (error, onError) => {
    if (error.code === 'auth/account-exists-with-different-credential') {
        const customData = error.customData;
        const email = customData.email;
        const tokenResponse = customData._tokenResponse;
        const pendingCredential = FacebookAuthProvider.credential(tokenResponse.oauthAccessToken);

        console.log(`[signInWithFacebook] Error info: `, JSON.stringify(error));

        if (email) {
            console.log(`[signInWithFacebook] Retrieved email: `, email);
            // Fetch the sign-in methods for the email
            fetchSignInMethodsForEmail(auth, email).then(async methods => {
                console.log(`[signInWithFacebook] Sign-in methods: `, methods);

                if (methods.includes('password')) {
                    // User exists with email/password sign-in
                    console.log(
                        `[signInWithFacebook] Email linked to password. Prompting for login.`,
                    );
                    const password = prompt(
                        `An account with email ${email} exists. Please enter the password to link your Facebook account.`,
                    );
                    signInWithEmailAndPassword(auth, email, password)
                        .then(userCredential => {
                            console.log('Signed in with email/password.');
                            linkWithCredential(userCredential.user, pendingCredential)
                                .then(() => {
                                    console.log('Linked Facebook to existing account.');
                                })
                                .catch(linkError => {
                                    console.error('Error linking credential:', linkError);
                                    if (onError) onError(linkError);
                                });
                        })
                        .catch(signInError => {
                            console.error('Error signing in with email/password:', signInError);
                            if (onError) onError(signInError);
                        });
                } else if (methods.includes('facebook.com')) {
                    // Facebook is already linked
                    signInWithCredential(auth, pendingCredential)
                        .then(userCredential => {
                            console.log('Signed in with Facebook credential.');
                        })
                        .catch(signInError => {
                            console.error('Error signing in with Facebook:', signInError);
                            if (onError) onError(signInError);
                        });
                } else {
                    console.log(
                        `[signInWithFacebook] No matching sign-in methods found. Attempting fallback linking.`,
                    );
                    linkWithCredential(auth.currentUser, pendingCredential)
                        .then(() => {
                            console.log('Fallback linking succeeded.');
                        })
                        .catch(linkError => {
                            console.error('Error in fallback linking:', linkError);
                            if (onError) onError(linkError);
                        });
                }
            });
        } else {
            console.error('[signInWithFacebook] No email address available in the error object.');
        }
    } else {
        console.error('Error signing in:', error);
        if (onError) onError(error);
    }
};

export const CheckLoggedIn = () => {
    return new Promise(resolve => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                console.log('[CheckLoggedIn] User is signed in, uid:', user.uid);
                resolve(true);
            } else {
                console.log('[CheckLoggedIn] User is logged out');
                resolve(false);
            }
        });
        // Optional: Unsubscribe to avoid memory leaks, though this will be called on component unmount
        return unsubscribe;
    });
};

export const isLoggedIn = () => {
    const loggedIn = auth.currentUser !== null;
    console.log('[isLoggedIn] User is signed in:', loggedIn);
    return loggedIn;
};

export const SignOutAndClearAllData = async () => {
    signOut(auth)
        .then(() => {
            // Sign-out successful.
            console.log('Signed out successfully');
            console.log(`Triggered ClearAllData`);
            localStorage.clear();
            window.location.reload();
        })
        .catch(error => {
            console.log('could not sign out');
            console.log(`Triggered ClearAllData`);
            localStorage.clear();
            window.location.reload();
        });
};

export const deleteAccount = async () => {
    try {
        const user = auth.currentUser;
        if (user) {
            await deleteUser(user);
            localStorage.clear();
            window.location.reload();
            console.log('[deleteAccount] Account deleted successfully');
        } else {
            console.log('[deleteAccount] No user is currently signed in');
        }
    } catch (error) {
        console.error('[deleteAccount] Error deleting account:', error);
    }
};

export default app;
