export const generateGuestUID = () => {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); // use high-precision timer if available
    }
    return 'guest-' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16); // Added parentheses around mixed operators
    });
};

export const checkGuestExpiration = () => {
    const expirationTime = localStorage.getItem('expirationTime');
    if (expirationTime) {
        const currentTime = new Date().getTime();
        if (currentTime >= parseInt(expirationTime)) {
            localStorage.removeItem('expirationTime');
            localStorage.removeItem('guestID');
            localStorage.removeItem('isGuest');
            console.log('Data expired. Cleared saved data.');
            return true; // Data expired
        } else {
            console.log('Data still valid until:', new Date(parseInt(expirationTime)).toLocaleString());
            return false; // Data still valid
        }
    } else {
        console.log('No expiration data found.');
        return true; // No expiration data found
    }
};

export const  isGuest = () => {
    return localStorage.getItem('isGuest') === 'true';
};

export const SignInAsGuest = async () => {
    try {
        if (checkGuestExpiration()) {
            const seconds  = 8 * 60 * 60; // 8 hours
            localStorage.setItem('guestID', generateGuestUID());
            localStorage.setItem('expirationTime', new Date().getTime() + (seconds * 1000)); 
            localStorage.setItem('isGuest', 'true');
        }
        
        console.log(`[SignInAsGuest] Guest Data Expires at: ${new Date(parseInt(localStorage.getItem('expirationTime'))).toLocaleString()}`);
        console.log(`[SignInAsGuest] GuestID is: ${localStorage.getItem('guestID')}`);
        window.location.reload();
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};