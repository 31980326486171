import { useNavigate } from 'react-router-dom';
import '../css/App.css';

export const PrivacyPolicyPg = () => {
    return (
        <div>
            <div className="center">
                <p>Todo: PrivacyPolicy</p>
            </div>
        </div>
    );
};