import React, { useState, useEffect } from "react";
import PartySocket from "partysocket";

const TestConnection = () => {
  const [joinCode, setJoinCode] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);

  const connectToSession = (code) => {
    const playerID = "my-viewer-player-id";
    const role = "viewer";
    const newSocket = new PartySocket({
      //host: `https://src.afrogames.partykit.dev`,
      host: `127.0.0.1:1999`,
      room: code,
      query: {
        role: role,
        id: playerID
      }
    });

    newSocket.addEventListener("message", (msg) => {
        console.log(`got the msg: ${msg.data}`);
        setMessages((prev) => [...prev, msg.data]);

        try {
            // Check for disconnect payload
            const payload = JSON.parse(msg.data);
            if (-101 in payload) {
                console.log("Received disconnect data:", payload);
                newSocket.close();
            } else{
                // send msg to unity for processing
            }
        } catch (error) {
            console.error("Error parsing or processing payload:", error);
        }
    });

    newSocket.addEventListener("open", () => {
      console.log("Connected!");
    });

    setSocket(newSocket);
    return newSocket;
  };

  const sendMessage = (socket) => {
    const playerID = "my-viewer-player-id";
    const role = "viewer";
    var testMsg = { 9999 : message };
    var payload = {
        role: role,  // ethier "host" or "viewer"
        id: playerID,
        target: "host" ,
        playerIDs: undefined,
        msg: JSON.stringify(testMsg),
    };  
    socket.send(JSON.stringify(payload));

    setMessage('');
  };

  return (
    <div>
      <input
        value={joinCode}
        onChange={(e) => setJoinCode(e.target.value)}
        placeholder="Enter join code"
      />
      <button onClick={() => connectToSession(joinCode)}>Join</button>

      <input
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type message"
      />
      <button onClick={() => sendMessage(socket)}>Send</button>

      <ul>
        {messages.map((msg, index) => (
          <li key={index}>{msg}</li>
        ))}
      </ul>
    </div>
  );
};

export default TestConnection;