import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignOutAndClearAllData } from '../firebase/firebase-handler';
import buildNumber from '../version_number.json';

export const GetVersionNum = ()=> {
  return `v${buildNumber.major}.${buildNumber.minor}.${buildNumber.patch}b`
};

export const VersionNumber = () => {
  return (
    <p
      className="version-text noselect"
      style={{
        position: 'fixed',
        bottom: '10px',  // Distance from the bottom of the window
        right: '10px',   // Distance from the right side of the window
        cursor: 'pointer',
        color: '#ffffffbf', // Semi-transparent white
        fontSize: '12px',    // Font size
        userSelect: 'none',  // Prevent text selection
      }}
    >
      {GetVersionNum()}
    </p>
  );
};
