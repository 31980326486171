import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebase-handler';
import { NavLink, useNavigate } from 'react-router-dom';

export const GuestSignUp = ({ signInAsGuest }) => {
    const navigate = useNavigate();

    const [errorMsg, setErrorMsg] = useState('');
    const [acceptedTOS, setAcceptedTOS] = useState(false);
    const [acceptedPP, setAcceptedPP] = useState(false);

    const continueAsGuest = async e => {
        e.preventDefault();

        if(!acceptedTOS)
        {
            console.log('[EmailSignUp] Player has not accepted TOS');
            setErrorMsg(`Error: Please accept the Terms of Service to continue`) 
            return;
        }

        if(!acceptedPP)
        {
            console.log('[EmailSignUp] Player has not accepted Privacy Policy');
            setErrorMsg(`Error: Please accept the Privacy Policy to continue`) 
            return;
        }

        signInAsGuest();
    };

    const goBack = () => {
        navigate('/login');
    };

    const toggleToSAgreed = () => {
        setAcceptedTOS(!acceptedTOS);
    };

    const togglePPAgreed = () => {
        setAcceptedPP(!acceptedPP);
    };

    const getFullUrl = (path) => {
        return `${window.location.origin}${path}`;
    };

    return (
        <div>
            <div className="center fadeIn">
                <div className='uk-text-center'>
                    <span className='subheader'>Guest</span>

                    <br></br>
                    <br></br>

                    <div className="square-border">
                        <img src='/imgs/profilepics/image_0.png' width='128' height='128' alt=''/>
                        <p>Profile Image</p>
                    </div>

                    <br></br>
                    <p>Before continuing all party players must accept our TOS and Privacy Policy</p>
                    
                    <button className={`toggle-button fixedSize ${acceptedTOS ? 'on' : 'off'}`} onClick={toggleToSAgreed}>
                        <input 
                            type="checkbox" 
                            className="checkbox" 
                            checked={acceptedTOS} 
                            onChange={toggleToSAgreed} 
                        />
                         I accept Party Trivia's 
                        <a 
                            href={getFullUrl(`/tos`)} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="button-link"
                        >
                            Terms of Service
                        </a>
                    </button>

                    <button className={`toggle-button fixedSize ${acceptedPP ? 'on' : 'off'}`} onClick={togglePPAgreed}>
                        <input 
                            type="checkbox" 
                            className="checkbox" 
                            checked={acceptedPP} 
                            onChange={togglePPAgreed} 
                        />
                        I accept Party Trivia's 
                        <a 
                            href={getFullUrl(`/privacypolicy`)} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="button-link"
                        >
                            Privacy Policy
                        </a>
                    </button>
                    
                    <br></br>

                    <button className='button fixedSize' onClick={continueAsGuest}>
                        Join Party
                    </button>

                    <br></br>

                    <font color='red'>{errorMsg}</font>
                </div>
            </div>

            <div className='overlay_button_container' onClick={goBack}>
                <img src='/imgs/arrow.png' width='36' height='36' alt='back'></img>
            </div>
        </div>
    );
};
