import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth, isLoggedIn, CheckLoggedIn } from './firebase/firebase-handler';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { WelcomePage } from './pages/WelcomePage';
import { EmailSignUp } from './pages/EmailSignUp';
import { EmailLogin } from './pages/EmailLogin';
import { ForgotPassword } from './pages/ForgotPassword';
import { TeamsOfServicePage } from './pages/Util_TOS';
import { PrivacyPolicyPg } from './pages/Util_PrivacyPolicy';
import { GuestSignUp } from './pages/GuestSignUp';
import GamePage from './pages/GamePage';
import TestConnection from './pages/TestConnection';
import { isGuest, SignInAsGuest } from './util/guestDataHandler';

function App({ }) {
    const [isAuthenticated, setAuthenticated] = useState(null);
    console.log('Loading App');

    useEffect(() => {
        // Get current status
        const checkLoginStatus = async () => {
            const loggedIn = await CheckLoggedIn();
            setAuthenticated(loggedIn || isGuest());
        };
        checkLoginStatus();

        // Facebook Login
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '260692702045146',
                cookie     : true,
                xfbml      : true,
                version    : 'v16.0'
            });
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
        
        // // Listen for authentication status changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user){
                setAuthenticated(true);
            }else{
                setAuthenticated(false);
            }
        });
        return () => unsubscribe();
    }, []);

    // Check if we should display loading
    const ignoredPaths = ['/tos', '/privacypolicy'];
    const currentPath = window.location.pathname; // Get the current path from window.location
    if (isAuthenticated === null && !ignoredPaths.includes(currentPath)) {
        return <div></div>; // Waiting for firebase auth to load
    }
    
    return (
        <BrowserRouter>
            <Routes>
                {isAuthenticated || isGuest() ? (
                    <>
                        <Route path="/" element={<GamePage/>} />
                    </>
                ) : (
                    <>
                        <Route path="/" element={<WelcomePage />} />
                        <Route path="/login" element={<EmailLogin />} />
                        <Route path="/signup" element={<EmailSignUp />} />
                        <Route path="/resetpassword" element={<ForgotPassword />} />
                        <Route path="/guestlogin" element={<GuestSignUp signInAsGuest={SignInAsGuest} />} />
                    </>
                )}
                <Route path="/tos" element={<TeamsOfServicePage />} />
                <Route path="/privacypolicy" element={<PrivacyPolicyPg />} />
                <Route path="/testing" element={<TestConnection />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
